@import "@/index.scss";

.ct-questions {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0px;
  }

  &__nav-part {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);

    &__btn-nav {
      width: fit-content !important;
    }
  }

  &__alert {
    width: 100%;
    margin-bottom: 20px;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    .MuiAlert-message {
      width: 100%;
      .join-part {
        width: 100%;
        display: flex;
        align-items: center;

        p {
          text-align: left;
        }

        button {
          width: fit-content !important;
          margin-left: auto;
        }

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  }

  &__body-part {
    width: 100%;
    padding: 20px 20px;
    @include vertical-display-layout(20px);
    align-items: center;

    @include media-breakpoint-down(xl) {
      padding: 20px 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 70px 0px;
    }

    .MuiAccordion-root {
      border-radius: 5px;
      background: rgba(var(--ui-tertiary-rgb), 0.4);
      border: 1px solid rgba(var(--ui-tertiary-rgb), 0.4);
      min-width: 80%;
      box-shadow: none;

      &::before {
        content: none;
      }

      .MuiCollapse-root {
        background: #ffffff;
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}
