@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@mixin auth-form-layout() {
  width: 100%;
  background-color: var(--ui-white);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  margin-bottom: 50px;

  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

@mixin vertical-display-layout($gap) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin text-ellipsis($width) {
  width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-blip {
  animation: blip ease-in-out infinite 1000ms;

  @keyframes blip {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
}

.text-area {
  .text-area-label {
    padding-left: 10px;
    margin-bottom: 10px;
  }

  .text-area-input {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    resize: none;

    &:focus {
      border-color: var(--ui-primary);
    }
  }

  &.error {
    .text-area-label {
      color: var(--ui-error);
    }
    .text-area-input {
      border-color: var(--ui-error);
    }
    p {
      color: var(--ui-error);
    }
  }
}

.yel-radio-row {
  border: 1px solid var(--ui-medium) !important;
  border-radius: 10px;
  padding: 5px;
  height: 56px;
  position: relative;

  @include media-breakpoint-down(xl) {
    height: 100px;
    padding: 10px !important;
  }

  @include media-breakpoint-down(md) {
    height: 100px;
    padding: 10px !important;
  }

  @include media-breakpoint-down(sm) {
    height: 130px;
    padding: 10px !important;
  }

  .my-label-radio {
    position: absolute;
    color: rgba(0, 0, 0, 0.38);
    top: -10px;
    left: 10px;
    font-size: 12px;
    background: var(--ui-white);
    padding: 0px 5px;
  }

  .my-row-radio-buttons-group {
    width: 100%;
    padding-left: 5px;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-down(lg) {
      flex-direction: row;
    }
  }
}

.yel-checkbox-list {
  width: 100%;
  border-radius: 10px;
  background: var(--ui-light);
  padding: 10px !important;

  .MuiFormLabel-root {
    color: var(--ui-medium) !important;
  }

  .yel-checkbox-option-container {
    max-height: 50vh;
    overflow: auto;

    .MuiFormControlLabel-root {
      // display: block;
      color: var(--ui-dark);

      .MuiSvgIcon-root {
        path {
          // color: var(--ui-primary);
        }
      }
    }

    /* width */
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px var(--ui-dark);
      border-radius: 20px;
      background-color: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: var(--ui-tertiary);
      border-radius: 15px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: var(--ui-warning);
    }
  }
}

.swal2-confirm {
  background-color: var(--ui-primary) !important;
}

// /* width */
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 10px var(--ui-disabled);
//   border-radius: 20px;
//   background-color: transparent;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: var(--ui-tertiary);
//   border-radius: 15px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: var(--ui-warning);
// }
