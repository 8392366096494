@import "@/index.scss";

.c-bottombar {
  height: 70px;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  z-index: 999;
  // background: var(--ui-primary) repeat url("../../../../assets/images/png/pattern.png");

  .mui-bottom-navigation {
    height: 100%;
    width: 100%;
    background: var(--ui-primary) repeat
      url("../../../../assets/images/png/pattern.png");
    border-radius: 20px 20px 0px 0px;

    .MuiButtonBase-root {
      border-radius: 30px;
      min-width: unset;
    }
  }
}
