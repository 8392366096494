@import "@/index.scss";

.ct-fiche {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__nav-part {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);
    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
      gap: 5px;
    }

    &__btn-nav {
      width: fit-content !important;
    }
  }

  &__body-part {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0px 0px;

    &__card-part {
      width: 70%;
      border-radius: 15px;
      background: var(--ui-white);
      box-shadow: 0px 0px 20px rgba(var(--ui-tertiary-rgb), 0.25);
      padding: 20px;
      margin: 0 auto;
      margin-bottom: 50px;
      @include vertical-display-layout(10px);
      align-items: center;

      @include media-breakpoint-down(xl) {
        width: 100%;
      }

      h4 {
        width: 100%;
        text-align: center;
      }

      &__avatar {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin: 20px 0px 50px 0px;
      }

      .form-part {
        @include vertical-display-layout(25px);
        width: 100%;
        align-items: center;

        .upper-box {
          width: 100%;
          // background: rgba(var(--ui-tertiary-rgb),0.4);
          // border-radius: 10px;
          // padding: 30px 10px;
        }

        .gender-radio-row {
          border: 1px solid var(--ui-disabled);
          border-radius: 10px;
          padding: 5px;
          height: 56px;
          position: relative;

          #id-civilité {
            position: absolute;
            color: rgba(0, 0, 0, 0.38);
            color: var(--ui-primary);
            top: -10px;
            left: 10px;
            font-size: 12px;
            background: var(--ui-white);
            padding: 0px 5px;
          }
          .row-radio-buttons-group {
            width: 100%;
            padding-left: 5px;
            display: flex;
            justify-content: flex-start;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-down(lg) {
              flex-direction: row;
            }
          }
        }

        .MuiAutocomplete-root {
          position: relative;
          .flag {
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
          }
          #country-select-demo {
            padding-left: 30px;
          }
        }

        .MuiInputLabel-root {
          color: var(--ui-primary);
        }
      }
    }
  }
}
