@import "@/index.scss";

.c-modal-research {
  width: 35%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  border-radius: 30px 0px 0px 30px;
  background-color: var(--ui-white);

  @include media-breakpoint-down(xl) {
    width: 40%;
  }
  @include media-breakpoint-down(lg) {
    width: 60%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0px;
  }

  &__title-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    background: var(--ui-primary);
    padding: 20px;
    border-radius: 20px 0px 0px 0px;
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }

    .back-icon {
      background: rgba(var(--ui-tertiary-rgb), 0.1);
    }

    .title {
      font-size: var(--title-size);
      font-weight: normal;
      flex: 1;
      color: var(--ui-secondary);
    }

    .close-icon {
      margin-left: auto;
    }
  }

  &__actions-part {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    gap: 20px;
    background: var(--ui-white);
    .action-chip {
      height: fit-content;
      padding: 10px 20px !important;
      border-radius: 30px;
      font-size: 1rem;
      font-weight: bold;
      box-shadow: 0px 0px 15px rgba(var(--ui-primary-rgb), 0.4);
      &.erase {
        box-shadow: 0px 0px 15px rgba(var(--ui-dark-rgb), 0.2);
      }
    }
  }

  &__body-part {
    background: var(--ui-white);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 40px;
    border-radius: 0px 0px 0px 20px;
    overflow: auto;
    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
      border-radius: 0px;
    }

    .label {
      font-size: 1.1rem;
      color: var(--ui-medium);
      font-weight: bold;
      margin-bottom: 5px;
      &.for-radio {
        height: 30px;
      }
    }

    .input-radio {
      background: var(--ui-light);
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .input-chip {
      background: var(--ui-light);
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      border-radius: 10px;
      margin-bottom: 10px;

      .filter-chip {
        width: fit-content;
        &.MuiChip-outlined {
          background: var(--ui-white);
        }
      }
    }

    .search-submit {
      height: fit-content;
      width: 100%;
      padding: 10px 20px;
      white-space: nowrap;
      margin-top: 30px;
    }
  }
}
