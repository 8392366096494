@import "@/index.scss";

.c-modal-rejected-modif {
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  background: var(--ui-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-height: 80vh;
  overflow: auto;

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  h3 {
    text-transform: uppercase;
  }

  &__content {
    width: 100%;
    ul {
      list-style: none;
      width: 100%;
      li {
        margin-bottom: 10px;
        &::before {
          content: "👉";
          margin-right: 10px;
        }
        b {
          color: var(--ui-medium) !important;
          transition: all ease-in-out 300ms;
          white-space: nowrap;
          &:hover {
            color: var(--ui-blue) !important;
            cursor: pointer;
          }

          &.value {
            margin-left: 10px;
            color: var(--ui-primary) !important;
            font-weight: 600;
            white-space: unset;
          }
        }
      }
    }
  }
}
