@import "@/index.scss";

.ct-favorite {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    height: 100%;
  }

  &__nav-part {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);

    .tabs-part {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .yel-toggle-group {
        height: 100%;

        .MuiToggleButton-root {
          border: none;
          border-radius: 5px;
          font-size: 1rem;
          text-transform: inherit;
          font-family: "Lato", sans-serif;
          font-weight: normal;
          &.Mui-selected {
          }
        }

        @include media-breakpoint-down(xl) {
          height: 50px;
          margin-bottom: 10px;
        }
      }
    }
  }

  &__body-part {
    width: 100%;
    height: calc(100% - 50px - 20px);
    background: var(--ui-white);
    padding: 20px 0px 20px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    &__upper-part {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 40px;
      gap: 20px;

      @include media-breakpoint-down(xl) {
        padding-left: 40px;
      }
      @include media-breakpoint-down(lg) {
        padding-left: 20px;
      }
      @include media-breakpoint-down(sm) {
        padding-left: 20px;
      }

      .btn {
        height: 30px;
        width: fit-content;
        padding: 10px 20px;
        border-radius: 20px;
      }
      .kpi {
        text-transform: inherit;
        color: var(--ui-medium);
        display: flex;
        align-items: center;
        gap: 5px;

        b {
          font-family: "Lato", sans-serif;
          font-weight: normal;
          color: var(--ui-warning);
        }
      }
    }
    &__list-manager-part {
      min-height: calc(100% - 300px);
    }

    &__alert {
      width: 100%;
      margin: 0px auto 20px auto;
      text-transform: inherit;
      background: #ededed !important;
      color: var(--ui-medium) !important;

      @include media-breakpoint-down(xl) {
        width: 100%;
      }
    }
  }
}
