@import "@/index.scss";

.detail-exchange-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  .infos-part {
    height: 100%;
    width: 300px;
    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .option-part {
    height: fit-content;
    width: 100%;
    display: flex;
    // flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: flex-start;
    .back-btn {
      width: fit-content;
      height: fit-content;
      padding: 5px 10px;
    }
    .detail-btn {
      width: fit-content;
      height: fit-content;
      padding: 5px 10px;
    }
    .opt-btn {
      width: fit-content;
      height: fit-content;
      padding: 5px 10px;
    }
  }
  .message-part {
    width: calc(100% - 300px - 20px);
    height: 100%;
    @include media-breakpoint-down(xl) {
      width: 100%;
      height: calc(100% - 70px);
    }
  }
}

.modal-detail_exchange-container {
  width: 40%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-down(xl) {
    width: 40%;
    border-radius: 20px 0px 0px 20px;
  }
  @include media-breakpoint-down(lg) {
    width: 60%;
    border-radius: 20px 0px 0px 20px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0px;
  }

  &__title-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    background: var(--ui-primary);
    padding: 20px;
    border-radius: 20px 0px 0px 0px;
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }

    .back-icon {
      background: var(--ui-tertiary);
    }

    .title-infos-username-age {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 2px;

      .username-age {
        width: fit-content;
        display: flex;
        width: fit-content;
        text-align: left;
        font-size: 1.3rem;
        color: var(--ui-secondary);
        .username {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(xl) {
            max-width: 300px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 300px;
          }
        }
        .age {
          margin-left: 8px;
        }
      }
      .dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: var(--ui-success);
        transform: translateY(-8px);

        &.offline {
          background: var(--ui-gray);
          opacity: 0.7;
        }
      }
    }
    .close-icon {
      margin-left: auto;
    }
  }

  &__body-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 40px;
    overflow: auto;
    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }
}
