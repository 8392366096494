@import "@/index.scss";

.ct-live {
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 0px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0px;
  }

  @include media-breakpoint-down(lg) {
    height: 100%;
  }

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  &__filter-part {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    position: relative;
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);

    .searchInput {
      @include media-breakpoint-down(sm) {
        transform: translateY(5px);
        width: calc(100% - 50px);
        margin-right: auto;
      }
    }

    &__speed-dial {
      position: absolute;
      top: 5px;
      right: 8px;

      .MuiSpeedDialAction-fab {
        background: var(--ui-dark);
        color: var(--ui-light);
      }

      .MuiSpeedDialAction-staticTooltipLabel {
        background: var(--ui-dark);
        color: var(--ui-light);
        white-space: nowrap;
      }
    }

    .MuiChip-deleteIcon {
      margin-left: auto !important;
    }

    &__btn-nav {
      width: 250px !important;
      height: 55px !important;
      border-radius: 10px !important;
      margin-top: -5px !important;

      @include media-breakpoint-down(sm) {
        width: 100% !important;
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__body-part {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 10px;

    @include media-breakpoint-down(xl) {
      padding: 20px 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 40px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px 5px;
    }

    .load-more {
      height: 100px;
      width: auto;
      border-radius: 20px;
    }
  }
}
