@import '@/index.scss';


 
.form-part-pratiques {
   @include vertical-display-layout (15px);
   padding-top: 20px;
   width: 100%;
   align-items: center;

   .niveau-radio-row{
        border: 1px solid var(--ui-medium);
        border-radius: 10px;
        padding: 5px;
        min-height: 86px;
        height: fit-content;
        position: relative;

        @include media-breakpoint-down(lg) {
            min-height: 150px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 230px;
        }

        #id-niveau {
            position: absolute;
            color: rgba(0, 0, 0, 0.38);
            top: -10px;
            left: 10px;
            font-size: 12px;
            background: var(--ui-white);
            padding: 0px 5px;
        }
        .row-radio-buttons-group{
            width: 100%;
            padding-left: 5px;
            display: flex;
            justify-content: flex-start;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            @include media-breakpoint-down(lg) {
            flex-direction: row ;
            }

        }
    }

    .switch-container{
        display: flex;
        align-items: center;
        height: 55px;
        gap: 50px;
        background: var(--ui-light);
        border-radius: 10px;
        padding-left: 10px;

        @include media-breakpoint-down(md) {
            height: 150px;
        }

        @include media-breakpoint-down(sm) {
            height: 150px;
        }
    }

}
