@import '@/index.scss';

.card-imam-speaker {

    width: calc( (100% / 3) - 20px );
    height: fit-content;
    background: var(--ui-white);
    border-radius: 15px;
    padding: 20px;
    border: 1px solid rgba(var(--ui-tertiary-rgb),0.3);
    box-shadow: 0px 0px 50px rgba(var(--ui-tertiary-rgb),0.2);
    display: flex;
    flex-direction: column;
    gap: 15px;


    @include media-breakpoint-down(xl) {
        width: calc( (100% / 2) - 20px );
    }

    @include media-breakpoint-down(lg) {
        width: calc( (100% / 2) - 20px );
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
    }



    .head-part {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        img{
            height: 100%;
            width: auto;
            background: rgba(var(--ui-tertiary-rgb),0.3);
            padding: 2px;
            border-radius: 50%;
        }

        .head-text {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-top: 2px;
            gap: 5px;

            h2{
                color: var(--ui-tertiary-shade);
                width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-size: var(--subtitle-size);
                text-transform: uppercase;
            }
            p{
                color: var(--ui-primary);
                font-size: var(--normal-size);
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }

    .text-bloc {
        display: flex;
        flex-direction: column;
        gap: 5px;
        b{
            font-size: var(--small-size);
        }
        .paragraphe{
            font-size: var(--small-size);
            color: var(--ui-medium);
            height: 90px;
            padding-left: 10px;
        }
    }

    .social-part {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 5px;
        width: 100%;
        .rounded-square{
            height: 25px;
            width: 25px;
            border-radius: 50%;
            background: var(--ui-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms ease-in-out;
            font-size: small;

            svg {
                height: 50%;
                width: auto;
            }

            &:hover {
                cursor: pointer;
                background: rgba(var(--ui-primary-rgb),0.5);
            }
        }
    }

}