@import "@/index.scss";

.ct-exchange {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  gap: 20px;
  overflow: auto;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0px;
  }

  @include media-breakpoint-down(lg) {
    height: calc(100% - 80px);
  }

  @include media-breakpoint-down(md) {
    height: calc(100% - 0px);
    padding-top: 20px;
  }
  &__recap-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    overflow: auto;

    .exchange-info-container {
      width: 30%;
      height: 100%;
      @include media-breakpoint-down(xl) {
        width: 100%;
        height: fit-content;
      }
    }
    .recap-list-container {
      width: calc(70% - 20px);
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      .nav-part {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .yel-toggle-group {
          height: 100%;

          .MuiToggleButton-root {
            border: none;
            border-radius: 5px;
            font-family: "Lata", sans-serif;
            font-weight: normal;
            &.Mui-selected {
            }
          }

          @include media-breakpoint-down(xl) {
            height: 50px;
            margin-bottom: 10px;
          }
        }
      }
      .list-manager-exchange-container {
        width: 100%;
        height: calc(100% - 50px - 20px);
      }
    }
  }

  &__detail-container {
    width: 100%;
    height: fit-content;
  }
}
