@import "@/index.scss";

.ct-fonctionnement {
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  position: relative;
  @include vertical-display-layout(10px);
  overflow: auto;

  @include media-breakpoint-down(lg) {
    margin-top: 20px;
  }

  button {
    height: fit-content;
    padding: 20px;
  }

  iframe {
    height: 500px;
    border-radius: 10px;
    padding: 20px 10px 10px 10px;
    background: #000000;
    @include media-breakpoint-down(sm) {
      height: 200px;
    }
  }

  .MuiTypography-caption {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--subtitle-size);
    position: absolute;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    width: 80%;
    overflow: hidden;
    line-height: 120%;

    @include media-breakpoint-down(lg) {
      top: 25px;
    }

    @include media-breakpoint-down(sm) {
      top: 5px;
    }
  }
}
