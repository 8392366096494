@import "@/index.scss";

.ct-staff {
  height: 100%;
  width: 100%;
  padding: 20px 80px 0px 80px;
  position: relative;
  @include vertical-display-layout(10px);
  overflow: auto;

  @include media-breakpoint-down(lg) {
    margin-bottom: 0px;
  }
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  .title-part {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--ui-tertiary-shade);

    &.about {
      justify-content: center;
    }
  }

  .img-card {
    width: 100%;
    border-radius: 10px;
    padding: 20px 10px;
    background: rgba(var(--ui-tertiary-rgb), 0.1);
    @include vertical-display-layout(10px);
    align-items: center;

    img {
      height: 90px;
      width: auto;
    }

    p {
      color: var(--ui-tertiary-shade);
    }
  }

  .text-card {
    width: 100%;
    border-radius: 10px;
    padding: 20px 10px;
    background: rgba(var(--ui-tertiary-rgb), 0.1);
    color: var(--ui-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    img {
      height: 100px;
      width: auto;
      padding: 0px 30px;
      border-radius: 10px;
      background: rgba(var(--ui-tertiary-rgb), 0.3);
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .about-text {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    padding: 50px;
    background: rgba(var(--ui-tertiary-rgb), 0.1);
    position: relative;
    font-size: var(--subtitle-size);
    color: var(--ui-medium);

    &::before {
      content: url("../../../../assets/images/svg/quotes-2.svg");
      position: absolute;
      top: 10px;
      left: 10px;
      transform: rotateY(180deg);
      height: 20px;
      width: auto;
    }

    &::after {
      content: url("../../../../assets/images/svg/quotes-1.svg");
      position: absolute;
      bottom: 10px;
      right: 10px;
      transform: rotateY(180deg);
      height: 20px;
      width: auto;
    }

    @include media-breakpoint-down(sm) {
      padding: 40px 10px;
    }
  }

  .logo-end {
    margin: 20px auto 50px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
  }
}
