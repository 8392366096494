@import "@/index.scss";

.ct-blacklist {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    height: 100%;
  }

  @include media-breakpoint-down(lg) {
    height: 100%;
    padding-bottom: 0px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 20px;
    height: 100%;
  }

  &__nav-part {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);

    &__btn-nav {
      width: fit-content !important;
    }
  }

  &__body-part {
    width: 100%;
    height: 100%;
    background: var(--ui-white);
    padding: 20px 0px 20px 0px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
    @include media-breakpoint-down(sm) {
      padding: 20px;
    }

    &__alert {
      width: 100%;
      margin: 0px auto 20px auto;
      text-transform: uppercase;

      @include media-breakpoint-down(xl) {
        width: 100%;
      }
    }
  }
}
