@import "@/index.scss";

.c-text-displayer {
  width: 100%;
  background: none;
  border: none;
  overflow: auto;
  padding: 5px;
  resize: none;
  -webkit-resize: none;
  -moz-resize: none;
  -ms-resize: none;
  -o-resize: none;
}
