@import "@/index.scss";

.list-manager-container {
  width: 100%;
  height: 100%;
  background: var(--ui-white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;

  .list-upper-part {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    .searchInput-alt {
      width: 40%;
      margin: 0;
      margin-left: 40px;
      height: 45px !important;
      @include media-breakpoint-down(xl) {
        width: calc(100% - 80px);
      }
      @include media-breakpoint-down(lg) {
        width: calc(100% - 80px);
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        width: calc(100% - 40px);
        margin-left: 20px;
      }
      .MuiInputBase-root {
        background: var(--ui-white);
        height: 45px !important;
        font-weight: bold;
        ::placeholder {
          font-weight: normal;
        }
      }
    }

    .filter-part {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      gap: 20px;

      .msg-filtered-box {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: var(--ui-white);
        transition: all ease-in-out 300ms;
        display: flex;
        gap: 3px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.send {
          background: var(--ui-blue);

          &:hover {
            background: var(--ui-blue);
            .sent {
              color: var(--ui-white);
            }
          }

          .sent {
            color: var(--ui-white);
          }

          small {
            font-weight: bold;
            color: var(--ui-light);
          }
        }

        &.receive {
          background: var(--ui-red);

          &:hover {
            background: var(--ui-red);
            .received {
              color: var(--ui-white);
            }
          }

          .received {
            color: var(--ui-white);
          }

          small {
            font-weight: bold;
            color: var(--ui-light);
          }
        }

        &:hover {
          background: rgba(var(--ui-secondary-rgb), 0.8);
          box-shadow: 0px 0px 10px #ededed;
          cursor: pointer;
        }

        .sent {
          color: var(--ui-blue);
          font-size: var(--title-size);
        }

        .received {
          color: var(--ui-red);
          font-size: var(--title-size);
        }

        small {
          font-size: 6.5px;
          color: var(--ui-gray);
        }
      }
    }
  }

  .list-part {
    width: 100%;
    margin-top: 20px;
    // height: calc(100% - 80px - 50px);
    height: 100%;
    border-radius: 10px;
    padding: 10px 40px 40px 40px;
    gap: 20px;
    position: relative;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    @media (min-width: 1800px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include media-breakpoint-down(2xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr 1fr;
      padding: 10px 10px 10px 10px;
    }
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }

    &.isForExchange {
      @media (min-width: 1800px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include media-breakpoint-down(2xl) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 1fr;
        padding: 30px;
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        padding: 30px;
      }
      @media (max-width: 350px) {
        padding: 10px;
      }
    }

    &.no-data {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}
