@import "@/index.scss";

.c-modal-infos-zawaj {
  width: 500px;
  padding: 20px;
  border-radius: 20px;
  background: var(--ui-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .modal-avatar {
    margin: 20px 0px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  p {
    text-align: left;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 30px;
    max-height: 65vh;
    overflow: auto;

    @include media-breakpoint-down(md) {
      max-height: 75vh;
    }

    b {
      color: var(--ui-primary);

      &.wha {
        color: var(--ui-dark);
      }
    }

    .bold {
      font-weight: bold;
    }

    .danger {
      color: var(--ui-error);
    }
  }

  .btn-modal {
    width: 50%;
    border-radius: 30px;
  }
}
