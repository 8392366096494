@import "@/index.scss";

.c-user-restriction-displayer-container {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .box {
    min-width: fit-content;
    padding: 20px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    background: rgba(var(--ui-tertiary-rgb), 0.4);

    .header-text {
      font-size: var(--subtitle-size);
      color: var(--ui-primary);
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
      b {
        color: var(--ui-dark);
        text-transform: initial !important;
      }
    }
    .body-text {
      font-size: 1rem;
      color: var(--ui-dark);
      font-weight: normal;
      max-width: 500px;
      text-align: center;
      margin-bottom: 30px;
      .primary {
        color: var(--ui-primary);
        cursor: pointer;
        transition: all ease-in-out 300ms;
        &:hover {
          color: rgba(var(--ui-primary-rgb), 0.7);
        }
      }
      ul {
        list-style: none;
        li {
          margin-bottom: 10px;
          &::before {
            content: "👉";
            margin-right: 10px;
          }
          b {
            color: var(--ui-medium) !important;
            transition: all ease-in-out 300ms;
            white-space: nowrap;
            &:hover {
              color: var(--ui-blue) !important;
              cursor: pointer;
            }

            &.value {
              margin-left: 10px;
              color: var(--ui-primary) !important;
              font-weight: 600;
              white-space: unset;
            }
          }
        }
      }
    }
    .btn {
      width: fit-content;
      height: fit-content;
      padding: 5px 15px;
      text-transform: inherit !important;
      font-size: 1rem;
      border-radius: 30px;
    }
  }
}
