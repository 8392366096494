@import "@/index.scss";

.c-echange-infos {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: var(--ui-white);
  border-radius: 10px;
  border: 1px solid rgba(var(--ui-tertiary-rgb), 0.3);
  box-shadow: 0px 0px 50px rgba(var(--ui-tertiary-rgb), 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 5px;
  padding: 10px;

  @include media-breakpoint-down(xl) {
    height: fit-content;
    padding: 30px;
    padding-bottom: 50px;
  }

  .return {
    position: absolute;
    top: 10px;
    left: 10px;
    width: fit-content;
  }

  .icon-circle {
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 2px solid var(--ui-medium);
    background: var(--ui-white);
    color: 2px solid var(--ui-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
  }

  p {
    text-transform: uppercase;
    color: var(--ui-tertiary);
    text-align: center;
    width: 80%;
    margin-top: 10px;
  }

  .name {
    font-size: var(--title-size);
    text-transform: uppercase;
    margin-top: 20px;
  }
  small {
    font-size: var(--normal-size);
    margin-top: 5px;

    &.inside {
      margin-bottom: 20px;
    }
  }

  .btn-container {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 20px;
    transform: translateY(15px);

    button {
      height: 50px;
      width: 100%;
      font-size: 1rem;
      text-align: center;

      .MuiBadge-badge {
        right: -5px;
        top: -5px;
      }
    }
  }

  .btn-inside {
    height: 45px;
    width: 80%;
    margin-top: 10px;

    @include media-breakpoint-down(xxl) {
      font-size: 0.7rem;
    }
  }
}

.modal-exchange-container {
  width: 40%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-down(xl) {
    width: 40%;
    border-radius: 20px 0px 0px 20px;
  }
  @include media-breakpoint-down(lg) {
    width: 60%;
    border-radius: 20px 0px 0px 20px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0px;
  }

  &__title-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    background: var(--ui-primary);
    padding: 20px;
    border-radius: 20px 0px 0px 0px;
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }

    .back-icon {
      background: var(--ui-tertiary);
    }

    .title-infos-username-age {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 2px;

      .username-age {
        width: fit-content;
        display: flex;
        width: fit-content;
        text-align: left;
        font-size: 1.3rem;
        color: var(--ui-secondary);
        .username {
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(xl) {
            max-width: 200px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 200px;
          }
        }
        .age {
          margin-left: 8px;
        }
      }
      .dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: var(--ui-success);
        transform: translateY(-8px);

        &.offline {
          background: var(--ui-gray);
          opacity: 0.7;
        }
      }
    }
    .close-icon {
      margin-left: auto;
    }
  }

  &__body-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 40px;
    overflow: auto;
    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }
}
