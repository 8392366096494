@import "@/index.scss";

.card-profile {
  min-width: 280px;
  //width: calc((100% / 3) - (10px * 2));
  width: 100%;
  height: fit-content;
  background: var(--ui-white);
  border-radius: 15px;
  padding: 25px;
  padding-top: 35px;
  //border: 1px solid rgba(var(--ui-tertiary-rgb),0.3);
  box-shadow: 0px 0px 40px #efefef;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  align-self: stretch;
  transition: all 0.2s ease-in-out;

  @include media-breakpoint-down(1500px) {
    //width: calc((100% / 3) - (10px * 2));
    min-width: 250px;
    width: 100%;
  }
  @include media-breakpoint-down(xl) {
    width: 100%;
  }
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  .visited-indicator {
    position: absolute;
    top: 20px;
    left: 7px;
    width: 8px;
    height: 50%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .visited {
      border-radius: 10px;
      width: 100%;
      height: calc(50% - 5px);
      background: rgba(var(--ui-tertiary-rgb), 0.4);
    }

    .visitor {
      border-radius: 10px;
      width: 100%;
      height: calc(50% - 5px);
      &.male {
        background: rgba(var(--ui-blue-for-female-shade-rgb), 0.4);
      }
      &.female {
        background: rgba(var(--ui-pink-for-male-shade-rgb), 0.4);
      }
    }
  }

  .visited-indicator-text {
    position: absolute;
    top: 10px;
    left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 0px 25px;
    .visited {
      font-size: 0.6rem;
      font-weight: bold;
      color: var(--ui-tertiary);
    }

    .visitor {
      font-size: 0.6rem;
      font-weight: bold;
      &.male {
        color: var(--ui-blue-for-female-shade);
      }
      &.female {
        color: var(--ui-pink-for-male-shade);
      }
    }
  }

  &:hover {
    transform: scale(0.98);
    background: rgba(var(--ui-secondary-rgb), 0.1);
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
    .pastille-part {
      width: fit-content;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 3px;
      .pastille {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--ui-white);
        border: 2px solid var(--ui-green);
        border-radius: 50%;

        svg {
          color: var(--ui-green);
          transform: scale(1.8);
        }

        &.ko {
          border: 2px solid var(--ui-error);
          svg {
            color: var(--ui-error);
            transform: scale(1.8);
          }
        }
      }
      .profil-id {
        white-space: nowrap;
        font-size: x-small;
        color: var(--ui-medium);
        font-weight: bold;
      }
    }

    .tag-info-part {
      width: calc(100% - 70px);
      display: flex;
      flex-direction: column;
      gap: 2px;
      .tag {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        color: var(--ui-medium);
        svg {
          font-size: 0.8rem;
        }
        p {
          width: calc(100% - 0.8rem - 5px);
          font-size: small;
          font-weight: normal;
          display: flex;
          align-items: center;
          gap: 0px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          span {
            width: 75px;
            margin-right: auto;
          }
          b {
            width: calc(100% - 80px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
          }
        }
      }
    }
  }
  &__middle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 2px;
    .profil-infos-username-age {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 2px;

      .username-age {
        width: fit-content;
        display: flex;
        width: fit-content;
        text-align: left;
        font-size: 1.3rem;
        color: var(--ui-dark);
        .username {
          max-width: 120px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .age {
          margin-left: 8px;
        }
      }
      .dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: var(--ui-success);
        transform: translateY(-8px);

        &.offline {
          background: var(--ui-gray);
          opacity: 0.7;
        }
      }
    }
    .profil-progress-text {
      flex: 1;
      font-size: x-small;
      text-align: left;
      font-weight: 400;
      color: var(--ui-green);
      &.bad {
        color: var(--ui-red);
      }
      &.middle {
        color: var(--ui-tertiary);
      }
    }
  }

  &__detail-part {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 85px;
    .detail-text-part {
      max-height: 50px !important;
      width: 100%;
      color: var(--ui-gray);
      font-size: small;
      text-align: left;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      display: -webkit-box;
    }
  }

  &__cloture-part {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    .cloture-text {
      width: 100%;
      color: var(--ui-dark);
      font-size: small;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
    }
    .MuiButtonBase-root {
      padding: 5px 10px;
      border-width: 2px;
      width: fit-content;
    }
    .cloture-text-small {
      width: 100%;
      color: var(--ui-gray);
      font-size: x-small;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0;
      b {
        color: var(--ui-medium);
      }
    }
  }

  &__bottom-part {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: center;
    gap: 5px;

    .MuiButtonBase-root {
      padding: 5px 10px;
      border-width: 2px;
    }
  }
}

.form-raison-part {
  width: 500px;
  padding: 50px 20px 20px 20px;
  border-radius: 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.modal-historique-container {
  width: 40%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-down(xl) {
    width: 40%;
    border-radius: 20px 0px 0px 20px;
  }
  @include media-breakpoint-down(lg) {
    width: 60%;
    border-radius: 20px 0px 0px 20px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0px;
  }

  &__title-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    background: var(--ui-primary);
    padding: 20px;
    border-radius: 20px 0px 0px 0px;
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }

    .back-icon {
      background: var(--ui-tertiary);
    }

    .title-infos-username-age {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 2px;

      .username-age {
        width: fit-content;
        display: flex;
        width: fit-content;
        text-align: left;
        font-size: 1.3rem;
        color: var(--ui-secondary);
        .username {
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(xl) {
            max-width: 300px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 300px;
          }
        }
        .age {
          margin-left: 8px;
        }
      }
      .dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: var(--ui-success);
        transform: translateY(-8px);

        &.offline {
          background: var(--ui-gray);
          opacity: 0.7;
        }
      }
    }
    .close-icon {
      margin-left: auto;
    }
  }

  &__body-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 40px;
    overflow: auto;
    @include media-breakpoint-down(xl) {
      padding: 20px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
