@import "@/index.scss";

.c-message-layout {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  @include media-breakpoint-down(sm) {
    width: 90%;
  }

  &.sent {
    border-radius: 30px 30px 5px 30px;
    margin-left: auto;

    .message-time {
      position: absolute;
      right: 5px;
      bottom: -15px;
      font-size: 0.7rem;
      color: var(--ui-gray);
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &.received {
    border-radius: 30px 30px 30px 5px;
    margin-right: auto;

    .message-time {
      position: absolute;
      left: 5px;
      bottom: -15px;
      font-size: 0.7rem;
      color: var(--ui-gray);
      font-weight: 400;
      white-space: nowrap;
    }
  }

  &.male {
    background: var(--ui-blue-for-female);

    .in-progress {
      width: 100%;
      text-align: right;
      font-weight: 400;
      font-size: small;
      color: var(--ui-blue);
      margin-top: 10px;
    }

    .valid {
      color: var(--ui-blue);
    }
  }

  &.female {
    background: var(--ui-pink-for-male);

    .in-progress {
      width: 100%;
      text-align: right;
      font-weight: 400;
      font-size: small;
      color: var(--ui-red);
      margin-top: 10px;
    }

    .valid {
      color: var(--ui-red);
    }
  }

  &.staff {
    border-radius: 30px;
    padding: 0px;
    width: 65%;
    margin: 0 auto;
    background: var(--ui-secondary);
    margin-bottom: 30px;

    p {
      text-align: center;
      color: var(--ui-medium);

      &.rejected {
        color: var(--ui-primary);
        font-weight: bold;
      }
    }

    .p {
      text-align: center;
      font-weight: bold;
      height: fit-content !important;
      max-height: 100px !important;
      font-size: 1rem;
      // min-height: 20px !important;
    }
  }

  &__container {
    max-width: 100%;
    width: auto;
    padding: 20px;
    position: relative;

    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }

    .p,
    p {
      width: 100%;
      position: relative;
      overflow-wrap: break-word;
      font-size: 1rem;
      font-weight: 500;
      text-align: left;
      color: var(--ui-medium);

      &.pending {
        color: var(--ui-disabled);
      }

      &.checked {
        position: absolute;
        width: 100%;
        padding: 0px 20px;
        text-align: left;
        font-size: var(--subtitle-size);
        color: var(--ui-gray);
        bottom: 0px;
        left: 0px;

        &.received {
          text-align: right;
        }

        &.read {
          color: blue;
        }
      }

      &.rejected {
        color: var(--ui-primary);
      }

      &.reason {
        color: var(--ui-dark);
      }
    }

    .note {
      width: 100%;
      border-radius: 5px;
      background: var(--ui-primary);
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 5px;
      gap: 5px;

      small {
        color: var(--ui-disabled);
        font-size: x-small;
      }

      span {
        color: var(--ui-secondary);
        font-size: small;
      }
    }
  }

  .message-time-moderation {
    font-size: 0.7rem;
    transform: translateX(-50%);
    color: var(--ui-gray);
    font-weight: 400;
    white-space: nowrap;
  }
}
