@import "@/index.scss";

.ct-delete-account {
  height: 100%;
  width: 100%;

  @include media-breakpoint-down(xl) {
    margin-bottom: 80px;
    //   height: calc(100% - 100px) !important;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 0px;
    //height: calc(100% - 0px) !important;
  }

  .delete-box {
    width: 70%;
    border-radius: 15px;
    background: var(--ui-white);
    box-shadow: 0px 0px 20px rgba(var(--ui-tertiary-rgb), 0.25);
    padding: 20px;
    margin: 0 auto;
    @include vertical-display-layout(10px);
    align-items: center;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .recap-part {
      height: 150px;
      width: 100%;
      border-radius: 20px;
      background: rgba(var(--ui-tertiary-rgb), 0.1);
      border: 2px solid var(--ui-primary);
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .text-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 70px;

        h3 {
          font-weight: bold;
          margin-bottom: 10px;
        }

        p {
          color: var(--ui-medium);

          &.delete {
            color: var(--ui-primary);
          }
        }

        @include media-breakpoint-down(sm) {
          align-items: center;
        }
      }

      .infos-part {
        min-height: 70px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 30px;
        background: var(--ui-primary);
        border-radius: 20px;

        p {
          color: var(--ui-light);
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        height: fit-content;
      }
    }

    .text-blip {
      animation: blip linear infinite 1500ms;

      @keyframes blip {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0.4;
        }
      }
    }

    .radio-input {
      display: flex;
      flex-direction: column;
    }
  }
}

.form-raison-part {
  width: 500px;
  padding: 50px 20px 20px 20px;
  border-radius: 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;

  @include media-breakpoint-down(md) {
    width: 95%;
  }

  .close-icon {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
