@import "@/index.scss";

.payment-confirm-container {
  height: 100vh;
  width: 100%;
  background: #73103e url(../../../assets/images/png/pattern.png) center center
    repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 50px;
  @include media-breakpoint-down(lg) {
    padding: 0px;
  }
  @include media-breakpoint-down(md) {
    padding: 0px;
  }

  .top {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 250px;
      height: auto;
    }
  }

  .big-title {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 200px;
    @include media-breakpoint-down(lg) {
      padding: 0px 50px;
    }
    @include media-breakpoint-down(md) {
      padding: 0px 20px;
    }

    p {
      text-align: center;
      font-weight: bold;
      font-size: 3rem;
      color: var(--ui-white);
      .color-text {
        color: var(--ui-tertiary);
        font-weight: bold;
      }

      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
  }

  .middle {
    width: 70%;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    background: var(--ui-secondary);
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .logo-mini {
      height: 40px;
      width: auto;
    }
    p {
      font-size: 1.2rem;
      color: var(--ui-medium);
      font-weight: bold;
    }
    @include media-breakpoint-down(lg) {
      width: calc(100% - 60px);
    }
    .btn-home {
      max-width: 100%;
      width: fit-content;
      font-weight: bold;
      padding: 10px 20px;
      margin: 20px 0px;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .bottom {
    width: 80%;
    padding: 20px;
    margin-top: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    &::before {
      content: "";
      background: var(--ui-tertiary);
      width: 90%;
      height: 1px;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }

    .copyright {
      width: fit-content;
      p {
        font-size: 1rem;
        color: var(--ui-white);

        a {
          color: var(--ui-white);
          font-weight: bold;
          transition: color ease-in-out 300ms;
          &:hover {
            cursor: pointer;
            color: var(--ui-tertiary);
          }
        }

        small {
          font-weight: normal;
        }
      }
    }

    .social {
      margin-left: auto;
      width: fit-content;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 10px;
      max-width: 200px;

      .rounded-square {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background: var(--ui-tertiary-shade);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 300ms;
        svg {
          color: var(--ui-primary);
          font-size: 0.9rem;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--ui-tertiary);
          svg {
            color: var(--ui-white);
          }
        }
      }
    }
  }
}
