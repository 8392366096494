@import '@/index.scss';

.c-sidebar {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  max-width: 330px;
  background: var(--ui-primary) repeat  url("../../../../assets/images/png/pattern.png");
  display: block;
  z-index: 3;
  --sidebar-top-height: 320px;
  //
  //background: var(--ui-medium);//safeview_at_the_office
  //


  @include media-breakpoint-down(lg) { 
    &.opened {
      display: block;
    }
  }

  @include media-breakpoint-up(lg) {
      display: block;
  } 



  .sidebar-top {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: var(--sidebar-top-height);
    //
    //visibility: hidden;//safeview_at_the_office
    //

    &__header-btn-container {
      height: 50px;
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      @include media-breakpoint-up(lg) { 
          display: none;
      }

      .rounded-square{
          height: 35px;
          width: 35px;
          border-radius: 10px;
          background: var(--ui-secondary);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 200ms ease-in-out;

          &:hover {
            cursor: pointer;
            background: rgba(var(--ui-secondary-rgb),0.5);
          }

          &:nth-child(2){
            margin-left: auto;
          }
      }
    }

    &__logo {
      margin-bottom: 20px;
      height: 50px;
      width: auto;

      @include media-breakpoint-down(lg) { 
        display: none;
      }
    }

    &__avatar {
      margin-bottom: 20px;
      height: 50px;
      width: 50px;
      position: relative;

      img{
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }

      &__badge{
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: var(--ui-success);
        border: 2px solid var(--ui-primary);
        position: absolute;
        bottom: 0;
        right: 0;

        &.error {
          background: var(--ui-warning);
        }

      }
    }

    &__username {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 1px;
      font-variant: small-caps;
      color: var(--ui-secondary);
      mix-blend-mode: normal;
    }

    &__identity_number {
      font-weight: 300;
      font-size: 10px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 1px;
      font-variant: small-caps;
      color: var(--ui-secondary);
      margin-bottom: 10px;
    }

    &__other-infos {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      gap: 40px;

      &__infos-bloc {
        display: flex;
        align-items: center;
        color: var(--ui-secondary);
        font-size: var(--subtitle-size);
        span{
          max-width: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__btn-container {
      width: 100%;
      padding: 0px 35px;

      &__available-btn {
        border-radius: 30px;
        height: 40px;
        height: fit-content;
      }
    }




  }

  .sidebar-menu {
    // display: flex;
    // flex-direction: column;
    overflow: auto;
    padding: 1rem 0;
    padding-left: 18px;
    height: calc(100% - var(--sidebar-top-height));

    @include media-breakpoint-down(lg) { 
      padding-right: 18px;
    }

    hr {
      width: calc(100% - 18px) ;
      margin: 40px 0px;
      border-color: rgba(var(--ui-secondary-rgb),0.3);
    }


    &__social-part {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-right: 18px;
      margin-top: 30px;
      gap:10px;

      .title {
        width: 100%;
        text-align: center;
        font-size: var(--title-size);
        font-weight: normal;
        color: var(--ui-tertiary-shade);
      }
      .bloc-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .rounded-square{
            height: 35px;
            width: 35px;
            border-radius: 10px;
            background: var(--ui-secondary);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 200ms ease-in-out;

            &:hover {
              cursor: pointer;
              background: rgba(var(--ui-secondary-rgb),0.5);
            }
        }
      }
    }
  }
}
