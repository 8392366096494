@import "@/index.scss";

.paiement-container {
  @include auth-form-layout();
  gap: 30px;
  width: 60%;
  @include media-breakpoint-down(lg) {
    width: 60%;
  }
  @include media-breakpoint-down(md) {
    width: 70%;
  }
  @include media-breakpoint-down(sm) {
    width: calc(100% - 20px);
  }
  position: relative;

  .back-icon {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .header-part {
    @include vertical-display-layout(10px);
    width: 100%;
    align-items: center;
    margin-top: 30px;

    .title {
      font-size: var(--title-size);
      font-weight: normal;
      color: var(--ui-tertiary);
    }

    .subtitle {
      font-size: var(--small-size);
      color: var(--ui-gray);
    }
  }

  .loader-part-verif {
    width: 100%;
    height: fit-content;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fef8ec;
    padding: 20px;
    gap: 0px;

    .logo-mini {
      height: 30px;
      width: auto;
    }

    .load-profil {
      width: 25%;
      height: auto;
      border-radius: 20px;
    }
    p {
      font-weight: bold;
      font-size: var(--normal-size);
      color: var(--ui-dark);
      text-align: center;
      margin-top: 20px;
      &.success {
        color: var(--ui-primary);
        font-size: var(--title-size);
      }

      .link-connect {
        font-weight: bold;
        color: var(--ui-primary);
        transition: all 300ms ease-in-out;
        cursor: pointer;
        &:hover {
          color: var(--ui-tertiary);
        }
      }
    }

    small {
      font-weight: bold;
    }
  }

  .footer-part {
    @include vertical-display-layout(5px);
    width: 100%;
    align-items: center;
    font-size: var(--normal-size);

    .link-primary {
      color: var(--ui-primary);
      transition: color 300ms ease-in-out;

      &:hover {
        color: rgba(var(--ui-primary-rgb), 0.7);
        cursor: pointer;
      }
    }

    .link-gray {
      color: var(--ui-gray);
      transition: color 300ms ease-in-out;
      font-size: var(--small-size);

      &:hover {
        color: rgba(var(--ui-gray-rgb), 0.7);
        cursor: pointer;
      }
    }
  }
}
