@import "@/index.scss";


.ct-desactivate-subscription-nav-part {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  // padding: 10px;
  // background: rgba(var(--ui-tertiary-rgb),0.1);

  .nav-part__btn-nav {
      width: fit-content !important;
  }
}

.ct-desactivate-subscription {
  height: 100%;
  width: 100%;

  @include media-breakpoint-down(xl) {
    margin-bottom: 80px;
    // height: calc(100% - 100px) !important;
  }

  @include media-breakpoint-down(md) {
    height: calc(100% - 100px); 
    margin-bottom: 0px;
    //height: calc(100% - 0px) !important;
  }

  .desactivate-box {
    width: 70%;
    border-radius: 15px;
    background: var(--ui-white);
    box-shadow: 0px 0px 20px rgba(var(--ui-tertiary-rgb), 0.25);
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 80px;
    @include vertical-display-layout(10px);
    align-items: center;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .desactivate-title {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      font-size: var(--subtitle-size);
      margin-bottom: 10px;
      b {
        color: var(--ui-primary);
      }
    }
    .paiement-radio-row {
      
      .row-radio-buttons-group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 10px;

        .formule-card{
            width: 100%;
            padding: 10px;
            background: rgba(var(--ui-light-rgb),0.5);
            box-shadow: 0px 0px 30px rgba(var(--ui-gray-rgb),0.2);
            display: flex;
            align-items: center;
            gap: 5px;
            border-radius: 10px;
            transition: all ease-in-out 300ms;
            .MuiFormControlLabel-root{
              flex: 1;
                .MuiFormControlLabel-label{
                    font-family: 'Lato', sans-serif;
                    font-weight: bold;
                    width: 100%;
                }
            }
    
            span{
                margin-left: auto;
                font-size: var(--subtitle-size);
                font-weight: bold;
            }
    
            &.active {
                background: var(--ui-secondary);
    
                span{
                    color: var(--ui-primary);
                }
            }
    
            &.error {
                border: 1px solid var(--ui-error);
            }
    
        }
      }



    }
    .recap-part {
      height: 150px;
      width: 100%;
      border-radius: 20px;
      background: rgba(var(--ui-tertiary-rgb), 0.1);
      border: 2px solid var(--ui-primary);
      padding: 20px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .text-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 70px;

        h3 {
          font-weight: bold;
          margin-bottom: 10px;
        }

        p {
          color: var(--ui-medium);
          &.desactivate {
            color: var(--ui-primary);
          }
        }

        @include media-breakpoint-down(sm) {
          align-items: center;
        }
      }

      .infos-part {
        min-height: 70px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 30px;
        background: var(--ui-primary);
        border-radius: 20px;

        p {
          color: var(--ui-light);
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        height: fit-content;
      }
    }

    .radio-input {
      display: flex;
      flex-direction: column;
    }
  }

}


#alert-dialog-description {
  b{
    color: var(--ui-primary);
  }
  .spe {
    color: var(--ui-dark);
    font-weight: 700;
  }
}


.form-stripe-part {
  width: 500px ;
  height: 30vh;
  padding: 50px 20px 20px 20px;
  border-radius: 20px ;
  background: var(--ui-white) ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed ;
  top: 50% ;
  left: 50% ;
  transform: translate(-50%,-50%) !important;

  @include media-breakpoint-down(md){
      width: 95%;
  }

  .close-icon {
      position: absolute;
      top: 0px;
      right: 0px;
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.8) !important;
}