@import "@/index.scss";

.historique-conversation-scroll-container {
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  .historique-conversation-card {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    img {
      height: 40px;
      width: auto;
      margin: 0 auto 20px auto;
    }

    .discussion-container {
      width: 100%;
      height: 100%;
      max-height: 70vh;
      border-radius: 10px;
      background: var(--ui-white);
      border: 1px solid var(--ui-tertiary);
      display: flex;
      flex-direction: column;
      padding: 20px;
      overflow: auto;
    }
  }
}
