@import "@/index.scss";

.terms-and-conditions-container {
  @include auth-form-layout();
  gap: 30px;
  width: 50%;
  @include media-breakpoint-down(lg) {
    width: 80%;
  }
  @include media-breakpoint-down(md) {
    width: 80%;
  }
  @include media-breakpoint-down(sm) {
    width: calc(100% - 20px);
  }
  position: relative;

  .header-part {
    @include vertical-display-layout(10px);
    width: 100%;
    align-items: center;
    margin-top: 30px;

    .title {
      font-size: var(--title-size);
      font-weight: normal;
      color: var(--ui-dark);
      text-align: center;
      line-height: 150%;
    }

    .subtitle {
      font-size: var(--small-size);
      color: var(--ui-gray);
    }
  }

  .form-part {
    @include vertical-display-layout(15px);
    width: 100%;
    align-items: left;

    .media-container {
      @include vertical-display-layout(15px);
      width: 100%;
      align-items: center;
      padding-bottom: 80px;

      iframe {
        @include media-breakpoint-down(sm) {
          height: 150px;
        }
      }

      img {
        width: 45%;
        height: auto;
      }
    }

    .title {
      font-size: var(--title-size);
      font-weight: normal;
      color: var(--ui-primary);
    }

    .checkbox-item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        font-size: var(--subtitle-size);
        font-weight: 400;

        &.important {
          font-weight: bold;
          color: var(--ui-error);
        }

        b {
          font-weight: bold;
          color: var(--ui-primary);
        }
      }
    }
  }

  .footer-part {
    @include vertical-display-layout(5px);
    width: 100%;
    align-items: center;
    font-size: var(--normal-size);

    .link-primary {
      color: var(--ui-primary);
      transition: color 300ms ease-in-out;

      &:hover {
        color: rgba(var(--ui-primary-rgb), 0.7);
        cursor: pointer;
      }
    }

    .link-gray {
      color: var(--ui-gray);
      transition: color 300ms ease-in-out;
      font-size: var(--small-size);

      &:hover {
        color: rgba(var(--ui-gray-rgb), 0.7);
        cursor: pointer;
      }
    }
  }
}
