@import "@/index.scss";

.modal-full-profile-container {
  width: 40%;
  height: 100%;
  border-radius: 20px 0px 0px 20px;
  background: var(--ui-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;

  @include media-breakpoint-down(xl) {
    width: 40%;
    border-radius: 20px 0px 0px 20px;
  }
  @include media-breakpoint-down(lg) {
    width: 60%;
    border-radius: 20px 0px 0px 20px;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
    border-radius: 0px;
  }

  &__title-part {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    background: var(--ui-primary);
    padding: 20px;
    border-radius: 20px 0px 0px 0px;
    position: relative;
    @include media-breakpoint-down(md) {
      border-radius: 0px;
    }

    .back-icon {
      background: var(--ui-tertiary);
    }

    .title-infos-username-age {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 2px;

      .username-age {
        width: fit-content;
        display: flex;
        width: fit-content;
        text-align: left;
        font-size: 1.3rem;
        color: var(--ui-secondary);
        .username {
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(xl) {
            max-width: 150px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 80px;
          }
        }
        .age {
          margin-left: 8px;
        }
      }
      .dot {
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: var(--ui-success);
        transform: translateY(-8px);

        &.offline {
          background: var(--ui-gray);
          opacity: 0.7;
        }
      }
    }
    .close-icon {
      margin-left: auto;
    }

    .toogle-action-btn {
      position: absolute;
      bottom: -20px;
      width: fit-content;
      height: fit-content;
      padding: 5px 15px;
      border-radius: 10px;
      left: 50%;
      transform: translateX(-50%);
      background: var(--ui-primary) !important;
      z-index: 99;
    }
  }

  &__actions-part {
    width: 100%;
    height: fit-content;
    .layout-part {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      gap: 20px;
      background: var(--ui-white);
      @include media-breakpoint-down(xl) {
        padding-top: 40px;
      }

      .action-chip {
        height: fit-content;
        padding: 10px 20px !important;
        border-radius: 30px;
        font-size: 1rem;
        font-weight: bold;
        box-shadow: 0px 0px 15px rgba(var(--ui-primary-rgb), 0.8);
        &.warning {
          color: var(--ui-dark);
          box-shadow: 0px 0px 15px rgba(var(--ui-warning-rgb), 0.8);
        }
      }
    }
  }

  &__body-part {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 20px 40px;
    overflow: auto;
    @include media-breakpoint-down(xl) {
      padding: 20px;
      padding-top: 40px;
    }
    @include media-breakpoint-down(lg) {
      padding: 20px;
      padding-top: 40px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px;
      padding-top: 40px;
    }

    .modal-loader-container {
      width: 100%;
      height: fit-content;
      padding: 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fef8ec;

      .logo-mini {
        height: 50px;
        width: auto;
      }

      .load-profil {
        width: auto;
        height: 50px;
        border-radius: 20px;
      }

      small {
        font-weight: bold;
        transform: translateY(-15px);
      }
    }

    .full-profile-card {
      padding-top: 20px;
      border-radius: 20px;
      background: var(--ui-white);
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__upper {
        width: 100%;
        height: fit-content;
        background: var(--ui-white);
        border-radius: 15px;
        padding: 20px;
        //border: 1px solid rgba(var(--ui-tertiary-rgb),0.3);
        box-shadow: 0px 0px 40px #efefef;
        display: flex;
        flex-direction: column;
        gap: 15px;
        position: relative;

        .pastille {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -20px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--ui-white);
          border: 2px solid var(--ui-green);
          border-radius: 50%;

          svg {
            color: var(--ui-green);
            transform: scale(1.5);
          }

          &.ko {
            border: 2px solid var(--ui-error);
            svg {
              color: var(--ui-error);
              transform: scale(1.5);
            }
          }
        }

        .profil-progress-text {
          width: 100%;
          text-align: center;
          font-weight: 400;
          color: var(--ui-green);
          font-size: x-small;
          margin-top: 5px;

          &.ko {
            color: var(--ui-gray);
          }
        }

        .modal-tag-info-part {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 5px;
          .profil-id-text {
            font-size: 1rem;
            margin-bottom: 5px;
          }
          .tag {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            color: var(--ui-dark);
            svg {
              font-size: 0.8rem;
            }
            p {
              width: calc(100% - 0.8rem - 5px);
              font-size: 1rem;
              font-weight: normal;
              display: flex;
              align-items: center;
              gap: 0px;
              span {
                width: 75px;
                margin-right: auto;
              }
              b {
                width: calc(100% - 100px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: right;
              }
            }
          }
          .date-part {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 0px;
            width: 100%;
            margin-top: 20px;
            .profil-date-text {
              font-size: small;
              color: var(--ui-gray);
            }
          }
        }
      }

      .MuiAccordion-root {
        background: var(--ui-white);
        border-radius: 10px;
        //border: 1px solid rgba(var(--ui-tertiary-rgb),0.3);
        box-shadow: 0px 0px 40px #efefef;
        transition: all ease-in-out 300ms;

        &:hover {
          background: rgba(var(--ui-tertiary-rgb), 0.5);
        }

        &::before {
          content: none;
        }

        .MuiCollapse-root {
          background: #ffffff;
          border-radius: 0px 0px 5px 5px;
        }
      }

      .accordion-title-container {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: var(--title-size);
        text-transform: uppercase;
      }

      .fiche-container {
        width: 100%;
        border-radius: 10px;
        padding: 10px;
        background: rgba(var(--ui-secondary-rgb), 0.5);

        .item-infos {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 5px;
          width: 100%;
          flex-direction: column;
          @include media-breakpoint-down(lg) {
            flex-direction: column;
          }

          .field-text {
            font-size: var(--subtitle-size);
            font-weight: bold;
            color: var(--ui-tertiary-shade);
            white-space: nowrap;

            @include media-breakpoint-down(lg) {
              white-space: unset;
            }
          }

          .value-text {
            font-size: var(--subtitle-size);
            font-weight: 400;
            color: var(--ui-medium);
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
