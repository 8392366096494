@import "@/index.scss";

.ct-home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  gap: 5px;
  overflow-x: hidden;
  background: var(--ui-white);
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba((var(--ui-tertiary-rgb)), 0.1);

  &__btn-top-container {
    height: fit-content;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__filter-part {
    width: 100%;
    padding: 40px 40px 10px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    position: relative;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      padding: 20px 20px 10px 20px;
      gap: 10px;
    }

    .searchInput {
      width: 40%;
      margin: 0;
      height: 45px !important;
      @include media-breakpoint-down(xl) {
        flex: 1;
      }
      @include media-breakpoint-down(lg) {
        flex: 1;
      }
      @include media-breakpoint-down(sm) {
        flex: 1;
      }
      .MuiInputBase-root {
        background: var(--ui-white);
        height: 45px !important;
        font-weight: bold;
        padding-right: 3px;
        ::placeholder {
          font-weight: normal;
        }
      }
    }

    .btn {
      height: 40px;
      width: fit-content;
      padding: 10px 20px;
    }

    .sm-btn {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }
  }

  .result {
    width: 100%;
    text-align: left;
    padding-left: 40px;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      padding-left: 20px;
    }
    span {
      color: var(--ui-medium);
    }
    b {
      color: var(--ui-primary);
      &:hover {
        cursor: pointer;
        color: var(--ui-tertiary);
      }
    }
  }

  &__body-part {
    width: 100%;
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    background: var(--ui-white);
    border-radius: 10px;
    overflow: auto;
    @media (max-width: 350px) {
      padding: 10px;
    }

    .load-more-home {
      height: 100px;
      width: 100px;
      border-radius: 20px;
      background: #fff;
      margin: 0 auto;
    }

    .profile-card-grid-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 20px;
      @media (min-width: 1800px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @include media-breakpoint-down(2xl) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 1fr;
      }
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }
    }
  }
}
