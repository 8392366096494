@import "@/index.scss";

.c-echange-message {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  background: var(--ui-white);
  border-radius: 10px;
  border: 1px solid rgba(var(--ui-tertiary-rgb), 0.3);
  display: flex;
  flex-direction: column;
  gap: 0px;
  transition: all 300ms ease-in-out;
  &.expanded {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }

  .top-part {
    height: fit-content;
    width: 100%;
    padding: 0px;
    border-bottom: 1px solid #ededed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 10px;
    .top-part-toolbar {
      padding: 10px;
      width: 100%;
      border-bottom: 1px solid #ededed;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      .refresh-conversation-btn {
        height: 30px !important;
        width: fit-content;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--ui-primary);
        transition: all 300ms ease-in-out;
        &:hover {
          cursor: pointer;
          background: var(--ui-tertiary);
          color: var(--ui-dark);
        }
        .refresh-conversation-text {
          font-size: 0.8rem;
          font-weight: bold;
          white-space: nowrap;
        }
      }
    }
    .toogle-btn {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ui-warning);
      border: 1px solid #ededed;
      color: var(--ui-dark);
      transition: all 300ms ease-in-out;
      position: absolute;
      right: 20px;
      bottom: 0px;
      z-index: 1000;
      transform: translateY(15px);
      &:hover {
        cursor: pointer;
        background: var(--ui-tertiary);
        color: var(--ui-dark);
      }
    }

    p {
      font-size: var(--normal-size);
      width: 100%;
      text-align: center;
      color: var(--ui-medium);
      margin: 10px;
    }
  }
  .header-exchange {
    width: 100%;
    height: fit-content;
  }

  .content-part {
    width: 100%;
    flex: 1;
    padding: 20px 20px 50px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    // overscroll-behavior: contain ;

    &.unavailable {
      height: calc(100% - 80px - 200px);
    }

    .no-text {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: var(--title-size);
        color: var(--ui-disabled);
        width: 80%;
        text-align: center;
      }
    }

    .expand-btn-absolute {
      position: sticky;
      top: 0px;
      width: 40px;
      aspect-ratio: 1;
      margin-left: auto;
      background: var(--ui-medium);
      color: var(--ui-light);
      z-index: 1999;
      &.expanded {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1999;
      }
    }
  }

  .bottom-part {
    height: fit-content;
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    background: var(--ui-light);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    margin-top: auto;

    .sroll-to-bottom {
      position: absolute;
      top: -50px;
      right: 20px;
      background: var(--ui-medium);
      color: var(--ui-light);
    }

    .sender-input {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      .send-message-input {
        flex: 1;
        border-radius: 10px;
        min-height: 50px;
        max-height: 150px;
        resize: none;
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-radius: 10px;
        font-size: 16px;
        line-height: 1.5;
        overflow-y: hidden;
        &:active {
          border: 1px soli var(--ui-primary);
        }
      }

      .send-btn {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--ui-gray);
        color: var(--ui-light);
        transition: all 300ms ease-in-out;
        font-size: xx-large;

        @include media-breakpoint-down(md) {
          height: 40px;
          width: 40px;
        }

        &:hover {
          cursor: pointer;
          background: var(--ui-tertiary);
          color: var(--ui-dark);
        }

        &.active {
          cursor: pointer;
          background: var(--ui-warning);
          color: var(--ui-dark);
        }
      }
    }
    small {
      font-weight: 400;
      color: var(--ui-gray);
      margin-left: 5px;
      margin-top: 10px;
    }

    &.unavailable {
      height: 200px;
      background: var(--ui-white);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      background: url(../../../../../../assets/images/png/logo-mini.png);
      background-repeat: no-repeat;
      background-size: 5%;
      background-position: bottom center;
      margin-bottom: 10px;

      .title-unavailable {
        font-size: var(--subtitle-size);
        font-weight: bold;
        color: var(--ui-primary);
      }
      .text-unavailable {
        width: 90%;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 400;
        color: var(--ui-dark);
        overflow: auto;
        padding: 10px;
        border-radius: 10px;
        background: var(--ui-light);
        border: 2px solid var(--ui-primary);
        height: 65%;

        @include media-breakpoint-down(lg) {
          font-size: 1.1rem;
        }

        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
    }
  }
}
