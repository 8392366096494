@import "@/index.scss";

.authentication-layout-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  //background-color: rgba(var(--primary-rgb),1);
  background-color: var(--ui-primary);
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-image: url("../../../assets/images/png/pattern.png");
    background-repeat: repeat;
    background-size: cover;
    pointer-events: none;
  }

  .form-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 70px;
    margin: 0 auto;
    gap: 20px;
    overflow: auto;

    .logo-title {
      width: 30%;
      height: auto;
      @include media-breakpoint-down(lg) {
        width: 30%;
      }
      @include media-breakpoint-down(md) {
        width: 30%;
      }
      @include media-breakpoint-down(sm) {
        width: calc(50% - 20px);
      }
    }
  }
}
