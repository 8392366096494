@import "@/index.scss";

.card-notification {
  width: 100%;
  height: fit-content;
  background: var(--ui-white);
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 0px 50px rgba(var(--ui-tertiary-rgb), 0.2);
  display: flex;
  flex-direction: column;
  gap: 15px;

  @include media-breakpoint-down(sm) {
    width: 100%;
    border-radius: 10px;
  }

  .chat-message__text {
    height: unset;
    // max-height: 250px;
    // overflow: hidden;
    // overflow-y: auto;
  }

  .head-part {
    // min-height: 120px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: 10px;

    .img-container {
      height: 100%;
      width: 40px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      background: var(--ui-white);
      border-radius: 10px;

      img {
        width: 70%;
        height: auto;
        padding: 2px;
        border-radius: 10px;
        filter: invert(68%) sepia(9%) saturate(2013%) hue-rotate(2deg)
          brightness(92%) contrast(84%);
        transform: rotateZ(10deg);
        opacity: 0.4;
      }
    }

    .middle-container {
      height: fit-content;
      width: calc(100% - 50px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 5px;
      h3 {
        color: var(--ui-primary);
        font-size: var(--normal-size);
        text-transform: uppercase;
        font-weight: bold;
        width: 90%;
      }
      .head-text {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding-top: 2px;
        overflow: auto;
        font-size: var(--normal-size);
        color: var(--ui-medium);
        white-space: normal;
      }

      .link-part {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 10px;
        gap: 20px;
      }
      .time-part {
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;

        .date {
          display: flex;
          align-items: center;
          color: var(--ui-medium);
          font-size: small;
          width: 100%;
          display: flex;
          align-items: center;
          @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
          }

          .corner {
            margin-left: auto;

            @include media-breakpoint-down(sm) {
              //display: none;
            }
            &.today {
              color: var(--ui-primary);
            }
            &.past {
              color: var(--ui-gray);
            }
          }
        }
      }
    }

    .close {
      position: absolute;
      top: -8px;
      right: -8px;
    }
  }
}
