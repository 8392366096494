@import '@/index.scss';


 
.form-part-aspect {
   @include vertical-display-layout (15px);
   padding-top: 20px;
   width: 100%;
   align-items: center;

    .MuiAutocomplete-root {
        position: relative;
        .flag{
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }
        #country-select-origine,
        #country-select-origine-autre,
        #country-select-nationalite,
        #country-select-nationalite-autre {
            padding-left: 30px;
        }
    }

    .switch-container{
        display: flex;
        align-items: center;
        height: 55px;
        gap: 50px;
        background: var(--ui-light);
        border-radius: 10px;
        padding-left: 10px;

        @include media-breakpoint-down(md) {
            height: 150px;
        }

        @include media-breakpoint-down(sm) {
            height: 150px;
        }
    }

}
