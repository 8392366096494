@import "@/index.scss";

.ct-engagement {
  height: 100%;
  width: 100%;
  padding: 0px 20px;
  position: relative;
  @include vertical-display-layout(10px);
  align-items: center;
  overflow: auto;
  border-radius: 10px;

  @include media-breakpoint-down(lg) {
    margin-bottom: 0px;
  }
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }

  .terms-and-conditions-container {
    @include auth-form-layout();
    gap: 30px;
    width: 90%;
    position: relative;
    margin-bottom: 50px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .header-part {
      @include vertical-display-layout(10px);
      width: 95%;
      align-items: center;
      margin-top: 30px;

      .title {
        font-size: var(--title-size);
        font-weight: normal;
        color: var(--ui-dark);
        text-align: center;
        line-height: 150%;
      }

      .subtitle {
        font-size: var(--small-size);
        color: var(--ui-gray);
      }
    }

    .form-part {
      @include vertical-display-layout(15px);
      width: 100%;
      align-items: left;

      .media-container {
        @include vertical-display-layout(15px);
        width: 100%;
        align-items: center;
        padding-bottom: 80px;
        iframe {
          @include media-breakpoint-down(sm) {
            height: 150px;
          }
        }

        img {
          width: 25%;
          height: auto;
        }
      }

      .title {
        font-size: var(--title-size);
        font-weight: normal;
        color: var(--ui-primary);
      }

      .checkbox-item {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font-size: var(--subtitle-size);
          font-weight: 400;

          &.important {
            font-weight: bold;
            color: var(--ui-error);
          }

          b {
            font-weight: bold;
            color: var(--ui-primary);
          }
        }
      }
    }

    .alert-part {
      width: 100%;
      .MuiAlert-message {
        width: 100%;
        .certified-part {
          width: 100%;
          border-radius: 10px;
          display: flex;
          align-items: center;

          img {
            height: 100px;
            animation: spin 4s infinite ease-in-out;
            width: auto;

            @keyframes spin {
              from {
                transform: rotateY(0deg);
              }

              to {
                transform: rotateY(360deg);
              }
            }
          }

          .sign-part {
            height: 100px;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;

            span {
              font-size: var(--title-size);
              font-weight: bold;
            }

            p {
              text-transform: capitalize;
            }

            @include media-breakpoint-down(sm) {
              height: fit-content;
            }
          }

          @include media-breakpoint-down(sm) {
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
  }
}
