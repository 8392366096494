@import "@/index.scss";

.ct-formulaire {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0px;
  }

  &__nav-part {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    gap: 20px;
    @include media-breakpoint-down(xl) {
      flex-wrap: wrap;
      gap: 5px;
    }
    // padding: 10px;
    // background: rgba(var(--ui-tertiary-rgb),0.1);

    &__btn-nav {
      width: fit-content !important;
    }
  }

  &__body-part {
    width: 100%;
    padding: 20px 20px;
    overflow: auto;

    @include media-breakpoint-down(xl) {
      padding: 20px 20px;
    }
    @include media-breakpoint-down(md) {
      padding: 20px 0px;
    }

    &__alert {
      width: 95%;
      margin: 0px auto 20px auto;
      @include media-breakpoint-down(xl) {
        width: 100%;
      }
      ul {
        list-style: none;
        .li-reason {
          margin-bottom: 10px;
          &::before {
            content: "👉";
            margin-right: 10px;
          }
          b {
            color: var(--ui-medium) !important;
            transition: all ease-in-out 300ms;
            white-space: nowrap;
            &:hover {
              color: var(--ui-blue) !important;
              cursor: pointer;
            }

            &.value {
              margin-left: 10px;
              color: var(--ui-primary) !important;
              font-weight: 600;
              white-space: unset;
            }
          }
        }
      }
    }

    &__card-part {
      width: 95%;
      border-radius: 15px;
      background: var(--ui-white);
      box-shadow: 0px 0px 20px rgba(var(--ui-tertiary-rgb), 0.25);
      padding: 20px;
      margin: 0 auto;
      margin-bottom: 50px;
      @include vertical-display-layout(20px);
      align-items: center;
      position: relative;

      @include media-breakpoint-down(xl) {
        width: 100%;
      }

      .pastille-container {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        .option {
          @include vertical-display-layout(5px);
          align-items: center;
          .option-ok {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 1px solid var(--ui-green);
            background: var(--ui-white);
            color: var(--ui-green);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
          }
          .option-ko {
            height: 50px;
            width: 50px;
            border-radius: 50%;
            border: 1px solid var(--ui-error);
            background: var(--ui-white);
            color: var(--ui-error);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
          }
          small {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.ok {
              color: var(--ui-green);
            }
            &.ko {
              color: var(--ui-error);
            }
          }
        }
      }

      .profil-progress-text {
        width: 100%;
        text-align: center;
        color: var(--ui-green);
        margin-bottom: 10px;
        margin-top: 60px;
      }

      .icon-tabs {
        font-size: var(--title-size);
      }

      .MuiTabs-indicator {
        background: var(--ui-tertiary);
      }

      div[role="tabpanel"] {
        .MuiBox-root {
          @include media-breakpoint-down(md) {
            padding: 0px;
          }
        }
      }
    }
  }
}
