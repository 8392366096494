@import "@/index.scss";

.voir-scroll-container {
  width: 100%;
  max-height: 100vh;
  overflow: auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;

  @include media-breakpoint-down(xl) {
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .voir-card {
    padding: 20px 20px 50px 20px;
    border-radius: 20px;
    background: var(--ui-secondary);
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &::after {
      position: absolute;
      content: "";
      top: 70px;
      left: 50%;
      transform: translateX(-50%);
      height: 5px;
      width: 50px;
      background: var(--ui-tertiary);
      border-radius: 5px;
    }

    img {
      height: 40px;
      width: auto;
      margin: 0 auto 20px auto;
    }

    .m-title {
      width: 100%;
      text-align: center;
      color: var(--ui-primary);
      font-weight: bold;
      font-size: var(--subtitle-size);
      padding: 0px 10px;

      svg {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .fatwa {
      margin: 0 auto;
      width: 100%;
      padding: 5px;
      padding-left: 20px;
      background: var(--ui-tertiary);
      border-radius: 20px;
    }

    .m-body {
      width: 100%;
      text-align: center;
      color: var(--ui-medium);
      font-weight: 400;
      font-size: var(--subtitle-size);
      padding: 0px 10px;
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        padding: 0px 10px;
      }
    }
  }
}
