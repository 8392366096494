@import "@/index.scss";

.ct-notification {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  gap: 20px;

  @include media-breakpoint-down(xl) {
    padding-bottom: 0px;
  }

  @include media-breakpoint-down(sm) {
    overflow: auto;
  }

  &__body-part {
    width: 100%;
    padding: 20px 20px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 30px;

    @include media-breakpoint-down(xl) {
      padding: 20px 20px;
    }
    @include media-breakpoint-down(lg) {
      padding: 0px 0px 100px 0px;
    }
    @include media-breakpoint-down(md) {
      padding: 0px 0px 100px 0px;
    }

    .load-more {
      height: 100px;
      width: auto;
      border-radius: 20px;
    }
  }
}
