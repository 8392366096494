@import "@/index.scss";

.c-echange-header {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  position: relative;
  z-index: 999;
  // background: var(--ui-light);
  background: #fafafa;
  border-bottom: 1px solid #ededed;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  gap: 10px;
  padding: 10px;

  @include media-breakpoint-down(md) {
    gap: 5px;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  .user-part {
    height: 100%;
    width: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 35px;
      @include media-breakpoint-down(md) {
        transform: scale(0.7);
      }
    }

    .username-age-head {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0px;
      p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8rem;
        text-align: center;
        color: var(--ui-dark);
        &.username {
          font-weight: bold;
        }
      }
    }

    .tag {
      width: 100%;
      height: fit-content;
      background: rgba(var(--ui-tertiary-rgb), 0.3);
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 5px;
      gap: 5px;
      color: var(--ui-dark);
      svg {
        width: 30px;
      }
      .city-country {
        width: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0px;
        p {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.7rem;
          &.city {
            font-weight: bold;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    small {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8rem;
      text-align: center;
      color: var(--ui-gray);
    }
  }

  .double-arrow {
    margin-top: 30px;
    @include media-breakpoint-down(md) {
      transform: scale(0.5);
    }
  }

  .staff-part {
    height: 100%;
    width: 20%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    img {
      height: 70px;
      width: auto;

      @include media-breakpoint-down(md) {
        transform: scale(0.7);
      }
    }

    p {
      text-align: center;
    }
  }
}
